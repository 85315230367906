import React from "react"
import DespreNoi from "../components/Despre-noi/DespreNoi"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import { PageBanner } from "../markup/layout/PageBanner"
function despre_Noi() {
  const pageTitle = "Despre Noi"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        pageTitle={pageTitle}
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "", slug: "" },
          hasParent: false,
        }}
      />
      <DespreNoi />
    </Layout>
  )
}

export default despre_Noi
