import React, { Component } from "react"
import { Link } from "gatsby"
import PartenersSection from "../PartnersSection"

import "./despre_noi.css"
import "../../css/plugins.css"
import "../../css/plugins.css"
import "../../css/style.css"
import "../../css/skin/skin-1.css"
import "../../css/templete.min.css"

const despreNoiSection1BkGr =
  require("../../images/banner/shutterstock_1936177924.png").default
const despreNoiSection2BkGr =
  require("../../images/banner/despre_noi_section2_background.png").default

class DespreNoi extends Component {
  render() {
    return (
      <div className="th-despre-noi__wrapper">
        <DespreLindaSection />
        <DespreLindaSection2 />
        <DespreLindaSection3 />
        <PartenersSection containerCustomStyling={{ margin: "25px 0" }} isAboutUs={true} />
      </div>
    )
  }
}

const DespreLindaSection = () => (
  <div className="section-full content-inner bg-white th-despre-noi-section">
    <div className="content-block">
      <div
        className="bg-white"
        style={{
          backgroundImage: `url(${despreNoiSection1BkGr})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
              <img
                src={
                  require("../../images/banner/shutterstock_1807239937.png")
                    .default
                }
                className="radius-sm"
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-12 m-b30">
              <div className="our-story">
                <span style={{ fontWeight: 600, fontSize: "18px" }}>
                  Despre Linda
                </span>
                <h3
                  className="title"
                  style={{
                    lineHeight: "45px",
                    fontWeight: 600,
                  }}
                >
                  SISTEMUL LINDA <br />
                </h3>
                <p style={{ fontSize: "15px", lineHeight: "25px" }}>
                  A fost dezvoltat in cadrul unui proiect de cercetare inovativ
                  integrand tehnologii din domenii diverse cu scop de optimizare
                  a solutiilor ingineresti existente si viitoare. In cadrul
                  proiectului multidisciplinar si-au unit eforturile trei
                  societatii romanesti cu expertiza in domeniile: industrie,
                  cercetare&dezvoltare, IT
                </p>
                <Link to={"/cercetare/sistemul-linda"} className="site-button">
                  Citeste mai mult
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const DespreLindaSection2 = () => (
  <div className="section-full content-inner bg-white th-despre-noi-section">
    <div className="content-block">
      <div
        className="bg-white"
        style={{
          backgroundImage: `url(${despreNoiSection2BkGr})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
              <img
                src={
                  require("../../images/banner/AEG_monitorizaredebit.jpg")
                    .default
                }
                className="radius-sm"
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-12 m-b30">
              <div className="our-story">
                <h3
                  className="title"
                  style={{
                    lineHeight: "34px",
                    fontWeight: 500,
                  }}
                >
                  AEG COMISSIONING
                </h3>
                <p>
                  Este o companie ce ofera servicii cu spectru larg în domeniul automatizarilor industriale și este integrator cât și dezvoltator de aplicații SCADA / HMI / PLC deschise pentru controlul proceselor industriale. Firma are competența de a acoperi toate nivelele de automatizare începand cu zona de câmp și terminând cu zona de management.
                </p>
                <div className="th-despre-noi__section2__links_wrapper">
                  <a href="http://www.aegcom.eu">www.aegcom.eu</a>

                  <Link
                    to={"/cercetare/aeg-comissioning"}
                    className="site-button"
                  >
                    Citeste mai mult
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const DespreLindaSection3 = () => {
  const SECTION_BANNER_IMG = require("../../images/about/Redans_despre-noiV2.jpg")
    .default

  // REDANS_LOGO
  const REDANS_LOGO = require("../../images/parteners/mPurpose-logo.png").default

  return (
    <div className="section-full content-inner bg-white th-despre-noi-section">
      <div className="content-block">
        <div
          className="bg-white"
          style={{
            backgroundImage: `url(${despreNoiSection2BkGr})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container aeg-thumbnail__wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 m-b30 our-story-thum aeg-thumbnail">
                <img
                  src={
                    SECTION_BANNER_IMG
                  }
                  className="radius-sm"
                  alt="REDANS"
                />
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="our-story">
                  <div className="our-story__header">
                    <h3
                      className="title"
                      style={{
                        lineHeight: "34px",
                        fontWeight: 500,
                      }}
                    >
                      REDANS: Research and Development for Automation and Network Systems
                    </h3>
                  </div>

                  <p>
                    Redans este o companie ce ofera servicii de proiectare si dezvoltare solutii de digitalizare industriala, solutii de diagnoza si management ale sistemelor de productie precum si solutii de optimizare in agricultura inteligenta.
                  </p>
                  <div className="th-despre-noi__section2__links_wrapper">
                    <a href="https://www.redans.eu">www.redans.eu</a>

                    <Link
                      to={"/cercetare/redans"}
                      className="site-button"
                    >
                      Citeste mai mult
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DespreNoi
